import React, { Component } from 'react'

class EnTete extends Component {
    render () {
        return (
            <div className="nomPrenom">
            <div className="titreEnTete">
                <h1 className="titre_nomPrenom">Marine Queste</h1>
                <h3 className="intitule">Chef de projet</h3>
                </div>
            </div>
        )
    }
}

export default EnTete